var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.$emit('on-Submit', _vm.locationAddForm)}}},[_c('b-row',{attrs:{"col":""}},[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Location Name","label-for":"mc-theme-name"}},[_c('validation-provider',{attrs:{"name":"Location Name","rules":"required|max:40|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"location_name",attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Location Name"},model:{value:(_vm.locationAddForm.name),callback:function ($$v) {_vm.$set(_vm.locationAddForm, "name", $$v)},expression:"locationAddForm.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Location Slug","label-for":"location_slug"}},[_c('validation-provider',{attrs:{"name":"Location Slug","rules":"required|max:40|min:3|noWhiteSpace"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"location_slug",attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Location Slug"},model:{value:(_vm.locationAddForm.slug),callback:function ($$v) {_vm.$set(_vm.locationAddForm, "slug", $$v)},expression:"locationAddForm.slug"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{attrs:{"col":""}},[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Theme","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Select Themes","label-for":"mc-select-theme","state":errors.length > 0 ? false : null}},[_c('v-select',{staticClass:"theme",attrs:{"multiple":"","close-on-select":false,"placeholder":"Select Themes","state":errors.length > 0 ? false : null,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.themes,"label":"name","clearable":false},model:{value:(_vm.locationAddForm.themes),callback:function ($$v) {_vm.$set(_vm.locationAddForm, "themes", $$v)},expression:"locationAddForm.themes"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-row',{attrs:{"col":""}},[_c('b-col',{staticClass:"mt-2",attrs:{"md":"7"}},[_c('div',{staticClass:"helper"}),_c('div',{staticClass:"drop align-center",on:{"dragover":function($event){$event.preventDefault();},"drop":_vm.onDrop}},[_c('div',{staticClass:"helper"}),(!_vm.imagePreview && !_vm.locationDetails.image)?_c('label',{staticClass:"btn btn-primary display-inline"},[_vm._v(" SELECT OR DROP AN IMAGE "),_c('input',{attrs:{"type":"file","accept":"image/*","name":"image"},on:{"change":_vm.onChange}})]):_vm._e(),(_vm.imagePreview)?_c('div',{staticClass:"align-center",class:{ image: true }},[_c('img',{staticClass:"img imagePreview_Cstm mt-2",attrs:{"src":_vm.imagePreview,"alt":"Image"}}),_c('br'),_c('br'),_c('button',{staticClass:"btn btn-primary mb-2",on:{"click":_vm.removeFile}},[_vm._v(" REMOVE ")])]):_vm._e(),(
                _vm.locationDetails.image && !_vm.imagePreview && _vm.$route.params.id
              )?_c('div',{staticClass:" display-inline align-center",class:{ image: true }},[_c('img',{staticClass:"img imagePreview_Cstm mt-2",attrs:{"src":_vm.locationDetails.image,"alt":"Image"}}),_c('br'),_c('br'),_c('button',{staticClass:"btn btn-primary mb-2",on:{"click":_vm.removeFile}},[_vm._v(" REMOVE ")])]):_vm._e()]),_c('b-alert',{staticClass:"mt-2",attrs:{"variant":"primary","show":""}},[_c('div',{staticClass:"alert-body"},[_c('span',[_vm._v("Allowed JPG, JPEG, GIF or PNG. Max size of 1MB.")])])])],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mt-2",staticStyle:{"display":"flex"},attrs:{"label":"Is Active","label-for":"mc-isactive"}},[_c('b-form-checkbox',{staticClass:"is_active ml-75",model:{value:(_vm.locationAddForm.is_active),callback:function ($$v) {_vm.$set(_vm.locationAddForm, "is_active", $$v)},expression:"locationAddForm.is_active"}})],1)],1)],1),_c('b-col',{staticClass:"demo-inline-spacing",attrs:{"md":"12"}},[_c('b-button',{attrs:{"disabled":_vm.loading,"variant":"primary","type":"submit"}},[_vm._v(" Submit "),(_vm.loading)?_c('div',{staticClass:"spinner-border spinner-border-sm"}):_vm._e()]),_c('b-button',{attrs:{"variant":"secondary","to":{
            name:
              _vm.checkLoginRole() === _vm.RoleEnum.SuperAdmin || _vm.checkAccess.view
                ? 'location-list'
                : 'dashboard'
          }}},[_vm._v(" Cancel ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }