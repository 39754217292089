<template>
  <div id="app">
    <LocationAdd v-if="!id && !isLoaded" ref="simpleRules" :key="$route.fullPath" :is-loaded="isLoaded" :location-details="locationDetails" @on-Submit="createLocation" />
    <LocationAdd v-if="id && isLoaded" ref="simpleRules" :key="$route.fullPath" :is-loaded="isLoaded" :location-details="locationDetails" @on-Submit="updateLocation" />

  </div>
</template>

<script>
import checkLoginRole from '@/helpers/checkLoginRole'
import locationService from '@/services/banner-management/location/location.service'
import { resConditionCheck } from '@/helpers/constant'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import errorResponseHandler from '@/services/errorHandler'

import LocationAdd from './LocationAdd.vue'

export default {
  name: 'LocationAddEdit',
  components: { LocationAdd },
  data() {
    return {
      isLoaded: false,
      locationDetails: {},
      id: this.$route.params.id ? this.$route.params.id : '',
      checkAccess: {},
      checkLoginRole

    }
  },
  watch: {
    async $route() {
      if (this.$route.name === 'location-add') {
        this.id = ''
        this.locationDetails = {}
        this.isLoaded = false
      } else if (this.$route.name === 'location-edit') {
        this.id = this.$route.params.id
        this.isLoaded = true
      }
    }
  },

  async mounted() {
    if (this.$route.params.id) {
      await this.getLocationById(this.$route.params.id)
    }
  },

  methods: {
    async createLocation(locationAddForm) {
      const themes = locationAddForm.themes.map(e => (e.id))
      const valid = await this.$refs.simpleRules.validationForm()
      if (valid) {
        const formdata = new FormData()
        formdata.append('name', locationAddForm.name)
        formdata.append('slug', locationAddForm.slug)
        formdata.append('is_active', locationAddForm.is_active)
        formdata.append('image', locationAddForm.image)
        formdata.append('themes', themes)
        this.$refs.simpleRules.loading = true
        await locationService.addLocation(formdata).then(res => {
          this.$refs.simpleRules.loading = false
          if (resConditionCheck(res.status) && res.data.data) {
            this.$router.push({ name: 'location-list' })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "CheckIcon",
                variant: "success",
              },
            });
          }
        }).catch(error => {
          setTimeout(() => {
            this.$refs.simpleRules.loading = false
          }, 3000)
          const errorData = errorResponseHandler(error)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: errorData,
              icon: "X-CircleIcon",
              variant: "danger",
            },
          });
        })
      }
    },

    async getLocationById(id) {
      await locationService.getLocationById(id).then(res => {
        this.isLoaded = true;
        if (resConditionCheck(res.status) && res.data.data) {
          this.locationDetails = res.data.data
        }
      }).catch(error => {
        const errorData = errorResponseHandler(error)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: errorData,
            icon: "X-CircleIcon",
            variant: "danger",
          },
        });
      })
    },

    async updateLocation(locationAddForm) {
      const themes = locationAddForm.themes.map(e => (e.id))
      const valid = await this.$refs.simpleRules.validationForm()
      if (valid) {
        const formdata = new FormData()
        formdata.append('name', locationAddForm.name)
        formdata.append('slug', locationAddForm.slug)
        formdata.append('is_active', locationAddForm.is_active)
        formdata.append('image', locationAddForm.image)
        formdata.append('themes', themes)
        this.$refs.simpleRules.loading = true
        locationService.updateLocation(this.$route.params.id, formdata).then(res => {
          this.$refs.simpleRules.loading = false
          if (resConditionCheck(res.status) && res.data.data) {
            this.$router.push('/location/list')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "CheckIcon",
                variant: "success",
              },
            });
          }
        }).catch(error => {
          this.$refs.simpleRules.loading = false
          const errorData = errorResponseHandler(error)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: errorData,
              icon: "X-CircleIcon",
              variant: "danger",
            },
          });
        })
      }
    }
  }
}

</script>
