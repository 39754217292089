<template>
  <b-card-code>
    <!-- forms -->
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="$emit('on-Submit', locationAddForm)">
        <b-row col>
          <b-col md="6">
            <b-form-group label="Location Name" label-for="mc-theme-name">
              <validation-provider
                #default="{ errors }"
                name="Location Name"
                rules="required|max:40|min:3"
              >
                <b-form-input
                  v-model="locationAddForm.name"
                  class="location_name"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Location Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Location Slug" label-for="location_slug">
              <validation-provider
                #default="{ errors }"
                name="Location Slug"
                rules="required|max:40|min:3|noWhiteSpace"
              >
                <b-form-input
                  v-model="locationAddForm.slug"
                  class="location_slug"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Location Slug"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row col>
          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="Theme"
              rules="required"
            >
              <b-form-group
                label="Select Themes"
                label-for="mc-select-theme"
                :state="errors.length > 0 ? false : null"
              >
                <v-select
                  v-model="locationAddForm.themes"
                  class="theme"
                  multiple
                  :close-on-select="false"
                  placeholder="Select Themes"
                  :state="errors.length > 0 ? false : null"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="themes"
                  label="name"
                  :clearable="false"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row col>
          <b-col md="7" class="mt-2">
            <div class="helper" />
            <div class="drop align-center" @dragover.prevent @drop="onDrop">
              <div class="helper" />
              <label
                v-if="!imagePreview && !locationDetails.image"
                class="btn btn-primary display-inline"
              >
                SELECT OR DROP AN IMAGE
                <input
                  type="file"
                  accept="image/*"
                  name="image"
                  @change="onChange"
                >
              </label>
              <div
                v-if="imagePreview"
                class="align-center"
                :class="{ image: true }"
              >
                <img
                  :src="imagePreview"
                  alt="Image"
                  class="img imagePreview_Cstm mt-2"
                >
                <br>
                <br>
                <button class="btn btn-primary mb-2" @click="removeFile">
                  REMOVE
                </button>
              </div>
              <div
                v-if="
                  locationDetails.image && !imagePreview && $route.params.id
                "
                class=" display-inline align-center"
                :class="{ image: true }"
              >
                <img
                  :src="locationDetails.image"
                  alt="Image"
                  class="img imagePreview_Cstm mt-2"
                >
                <br>
                <br>
                <button class="btn btn-primary mb-2" @click="removeFile">
                  REMOVE
                </button>
              </div>
            </div>
            <b-alert class="mt-2" variant="primary" show>
              <div class="alert-body">
                <span>Allowed JPG, JPEG, GIF or PNG. Max size of 1MB.</span>
              </div>
            </b-alert>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group
              style="display:flex"
              label="Is Active"
              label-for="mc-isactive"
              class="mt-2"
            >
              <b-form-checkbox
                v-model="locationAddForm.is_active"
                class="is_active ml-75"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <!-- submit button -->
        <b-col md="12" class="demo-inline-spacing">
          <b-button :disabled="loading" variant="primary" type="submit">
            Submit
            <div v-if="loading" class="spinner-border spinner-border-sm" />
          </b-button>
          <b-button
            variant="secondary"
            :to="{
              name:
                checkLoginRole() === RoleEnum.SuperAdmin || checkAccess.view
                  ? 'location-list'
                  : 'dashboard'
            }"
          >
            Cancel
          </b-button>
        </b-col>
        <!-- </b-row> -->
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormCheckbox,
  BAlert
} from "bootstrap-vue";
import { required, alpha } from "@core/utils/validations/validations";
import checkLoginRole from "@/helpers/checkLoginRole";
import { RoleEnum, resConditionCheck } from "@/helpers/constant";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import themeService from "@/services/banner-management/theme/theme.service";
import errorResponseHandler from "@/services/errorHandler";
import accessRightCheck from "@/helpers/accessRightCheck";

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BAlert,
    BFormCheckbox,
    vSelect
  },

  props: {
    locationDetails: {
      type: Object,
      required: true
    },
    isLoaded: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      locationAddForm: {
        name: this.locationDetails?.name || "",
        slug: this.locationDetails?.slug || "",
        themes: this.locationDetails?.themes || [],
        is_active: this.locationDetails?.isActive,
        image: this.locationDetails?.image || ""
      },
      required,
      alpha,
      checkLoginRole,
      RoleEnum,
      loading: false,
      profileFile: null,
      selectedFile: null,
      staticLink: "",
      imagePreview: "",
      file: "",
      themes: [],
      checkAccess: {}
    };
  },

  mounted() {
    if (!this.$route.params.id) {
      this.locationAddForm.is_active = true;
    }
    this.checkAccess = accessRightCheck(this.$route.meta.module.name);
    this.getThemeDropDown();
  },

  methods: {
    async getThemeDropDown() {
      try {
        const res = await themeService.getThemeListing();
        if (resConditionCheck(res.status) && res.data.data) {
          this.themes = res.data.data;
        }
      } catch (error) {
        const errorData = errorResponseHandler(error);

        this.$toast({
          component: ToastificationContent,
          props: {
            title: errorData,
            icon: "X-CircleIcon",
            variant: "danger"
          }
        });
      }
    },

    onDrop(e) {
      e.stopPropagation();
      e.preventDefault();
      const { files } = e.dataTransfer;
      this.createFile(files[0]);
    },

    onChange(e) {
      const { files } = e.target;
      this.createFile(files[0]);
    },

    createFile(file) {
      if (
        (file.type === "image/jpeg"
          || file.type === "image/jpg"
          || file.type === "image/png"
          || file.type === "image/gif")
        && file.size <= 1048576
      ) {
        this.locationAddForm.image = file;
        this.imagePreview = URL.createObjectURL(file, file.name);
      } else {
        this.locationAddForm.image = null;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Only JPG, JPEG, GIF or PNG Allowed with Max Size Of 1 MB",
            icon: "X-CircleIcon",
            variant: "danger"
          }
        });
      }
    },

    removeFile() {
      if (this.locationDetails.image) {
        this.locationDetails.image = "";
      } else {
        this.imagePreview = "";
      }
    },

    async validationForm() {
      let valid = null;
      await this.$refs.simpleRules.validate().then(success => {
        valid = !!success;
      });
      return valid;
    }
  }
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.dropBtn {
  background-color: #d3394c;
  border: 0;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  padding: 15px 35px;
  position: relative;
}

.dropBtn:hover {
  background-color: #722040;
}

input[type="file"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.align-center {
  text-align: center;
}

.hidden {
  display: none !important;
}

.hidden.image {
  display: inline-block !important;
}

.display-inline {
  display: inline-block;
  vertical-align: middle;
}

.imagePreview_Cstm {
  border: 1px solid #f6f6f6;
  display: inline-block;
  width: 400px;
  height: 250px;
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  padding: 0 10px;
}

.drop {
  background-color: #f2f2f2;
  border: 4px dashed #ccc;
  background-color: #f6f6f6;
  border-radius: 2px;
  height: 400px;
  width: 600px;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.drop .btn-primary {
  padding-left: 10px;
  padding-right: 10px;
}
</style>

<style>
.fileUpload {
  position: relative;
  overflow: hidden;
  margin: 10px;
}
</style>
